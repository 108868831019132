import './FirstAccess.scss'
import { values, validation } from './form'
import useFormFirstAccess from './hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { Icon, Button, If } from '@redsales/ds'
import AuthFeedback from 'components/Auth/Feedback'
import Fields from 'components/Fields'
import { useForm as useFormHook } from 'react-hook-form'

const FirstAccess = ({ location }) => {
	const { registered, onSubmit, onConcluded, isRequestingAPI, hasError } =
		useFormFirstAccess({
			location,
		})

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
	} = useFormHook({
		defaultValues: values,
		mode: 'onChange',
		...(validation && { resolver: yupResolver(validation) }),
	})

	return (
		<>
			<If
				condition={registered}
				renderElse={
					<If
						condition={hasError}
						renderElse={
							<div className="first-access">
								<Icon name="key" className="first-access__top-icon" />

								<h1 className="first-access__title logged-out__title">
									Primeiro acesso
								</h1>

								<p className="first-access__description logged-out__description">
									Olá! Você precisa criar uma senha <br />
									para acessar a sua conta.
								</p>

								<form
									className="first-access__form"
									onSubmit={handleSubmit(onSubmit)}
								>
									<Fields.Input.Password
										type="password"
										name="password"
										control={control}
										checkValidations={true}
										placeholder="Digite a senha"
										className="first-access__input"
									/>
									<Fields.Input.Text
										type="password"
										name="repeated_password"
										control={control}
										className="first-access__input"
										placeholder="Confirme sua senha"
									/>
									<Button
										size="big"
										type="submit"
										isLoading={isRequestingAPI}
										className="first-access__submit"
										disabled={!isValid || isRequestingAPI}
									>
										Enviar
									</Button>
								</form>
							</div>
						}
						renderIf={
							<AuthFeedback
								icon="lock"
								title="Erro na redefinição"
								description={`
					Erro no processo ou link expirado. Entre em contato com o administrador da sua conta para solicitar o desbloqueio.
					`}
								button="Voltar para a tela de acesso"
								submitComponent="link"
								onClick={onConcluded}
								data-test="feedback"
							/>
						}
					/>
				}
				renderIf={
					<AuthFeedback
						icon="party-popper"
						title="Senha cadastrada com sucesso!"
						description={`
              A sua senha foi cadastrada e você já pode <br />
              acessar a sua conta na plataforma.
            `}
						button="Acessar a plataforma"
						onClick={onConcluded}
					/>
				}
			/>
		</>
	)
}

export default FirstAccess
