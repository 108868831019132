import { navigate } from "gatsby"
import { useState } from "react"
import { useParameters } from "hooks/useParameters"
import services from "services"

const useFormFirstAccess = ({ location }) => {
	const [registered, setRegistered] = useState()
	const [hasError, setHasError] = useState(false)
	const [isRequestingAPI, setIsRequestingAPI] = useState(false)
	const { email, token } = useParameters("email", "token")

	const onSubmit = (data) => {
		setIsRequestingAPI(true)
		services.auth
			.signup({ token, email, ...data })
			.then(handleFirstAccess)
			.then(() => setIsRequestingAPI(false))
	}

	const onConcluded = () => navigate(ROUTES.login.path)

	const handleFirstAccess = (data) => {
		const error = data?.error

		setHasError(error)

		if (!error) return setRegistered(true)
	}

	return {
		registered,
		onSubmit,
		onConcluded,
		hasError,
		isRequestingAPI,
	}
}

export default useFormFirstAccess
